/* eslint-disable camelcase */
import dayjs, { Dayjs } from 'dayjs'
import { ContentState, EditorState, convertFromHTML, convertFromRaw, convertToRaw } from 'draft-js'

import { createErrorsHandlers, prepareSorter } from '../../utils'
import { BackendError } from '../RequestError'
import { SorterOrder } from '../SorterOrder'
import { fetchApi } from '../fetchApi'
import { QuestionType } from '../forms'
import { TableColumnVariableSource } from '../variables'

export enum RecruitmentStudyStatus {
  Draft = 'DRAFT',
  Recruiting = 'RECRUITING',
  Ended = 'ENDED',
  Archived = 'ARCHIVED'
}

export enum PaymentType {
  Visit = 'VISIT',
  Study = 'STUDY'
}

export enum RecruitmentDurationUnit {
  Days = 'DAYS',
  Weeks = 'WEEKS',
  Months = 'MONTHS',
  Years = 'YEARS'
}

export interface RecruitmentQrCode {
  center: string
  token: string
}

export enum WashOutPeriodType {
  Days = 'DAYS',
  Weeks = 'WEEKS',
  Months = 'MONTHS'
}

interface RemoteRecruitmentQrCodes {
  qr_codes?: Record<string, string>
}

const parseRemoteRecruitmentQrCodes = (qr: RemoteRecruitmentQrCodes): RecruitmentQrCode[] => {
  return qr ? Object.entries(qr).map(([center, token]) => ({ center, token })) : []
}

export interface SourcedRecruitmentVariable {
  variable: string
  source: TableColumnVariableSource.RecruitmentSurvey | TableColumnVariableSource.SubjectDatabase
}

interface RemoteRecruitmentStudy {
  center_ids: string[]
  target_date: string
  target: number
  name: string
  recruiter_name: string
  reference_number: string
  uuid: string
  status: RecruitmentStudyStatus
  active: boolean
  display_name: string
  summary: string
  header_image: string
  details: string
  age: string
  sex: string
  visit_number: number
  duration: number
  duration_unit: RecruitmentDurationUnit
  payment_type: PaymentType
  payment: number
  currency: string
  edc_study: string
  count_new: number
  count_qualified: number
  count_rejected: number
  progress: number
  table_variables: SourcedRecruitmentVariable[]
  qr_codes: RemoteRecruitmentQrCodes
  wash_out_active: boolean
  wash_out_period: number
  wash_out_period_type: WashOutPeriodType
  wash_out_zones: string[]
  expected_duration: number
  expected_duration_type: WashOutPeriodType
  study_zones: string[]
}

export interface RecruitmentStudy {
  id: string
  name: string
  reference: string
  centerIds: string[]
  recruiterName: string
  target: number
  targetDate: Dayjs
  candidatesCount: number
  qualifiedCount: number
  rejectedCount: number
  progress: number
  status: RecruitmentStudyStatus
  active: boolean
  displayName: string
  summary: string
  details: ContentState | EditorState
  image: string
  age: string
  sex: string
  visitNumber: number
  duration: number
  durationUnit: RecruitmentDurationUnit
  paymentType: PaymentType
  payment: number
  currency: string
  edcStudy: string
  tableVariables: SourcedRecruitmentVariable[]
  qrCodes: RecruitmentQrCode[]
  washOutActive: boolean
  washOutPeriod: number
  washOutPeriodType: WashOutPeriodType
  washOutZones: string[]
  expectedDuration: number
  expectedDurationType: WashOutPeriodType
  studyZones: string[]
}

const parseDetailsForSave = (details: ContentState | EditorState) => {
  try {
    return JSON.stringify(convertToRaw((details as EditorState)?.getCurrentContent()))
  } catch {
    return JSON.stringify(convertToRaw(details as ContentState))
  }
}

const parseDetails = (remoteDetails: string) => {
  try {
    return convertFromRaw(JSON.parse(remoteDetails))
  } catch {
    const blocksFromHTML = convertFromHTML(remoteDetails)
    return ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap)
  }
}

const parseRemoteRecruitmentStudy = (study: RemoteRecruitmentStudy): RecruitmentStudy => {
  return {
    id: study.uuid || undefined,
    name: study.name,
    target: study.target,
    recruiterName: study.recruiter_name,
    reference: study.reference_number,
    centerIds: study.center_ids.map(String),
    targetDate: study.target_date && dayjs(study.target_date, 'YYYY-MM-DD'),
    status: study.status,
    active: study.active,
    displayName: study.display_name,
    summary: study.summary,
    image: study.header_image,
    details: study.details && parseDetails(study.details),
    age: study.age,
    sex: study.sex,
    visitNumber: study.visit_number,
    duration: study.duration,
    durationUnit: study.duration_unit,
    paymentType: study.payment_type || PaymentType.Visit,
    payment: study.payment,
    currency: study.currency,
    edcStudy: study.edc_study,
    candidatesCount: study.count_new || 0,
    qualifiedCount: study.count_qualified || 0,
    rejectedCount: study.count_rejected || 0,
    progress: study.progress,
    tableVariables: study.table_variables,
    qrCodes: parseRemoteRecruitmentQrCodes(study.qr_codes),
    washOutActive: study.wash_out_active,
    washOutPeriod: study.wash_out_period,
    washOutPeriodType: study.wash_out_period_type,
    washOutZones: study.wash_out_zones,
    expectedDuration: study.expected_duration,
    expectedDurationType: study.expected_duration_type,
    studyZones: study.study_zones
  }
}

const parseRecruitmentStudyForSave = (study: Partial<RecruitmentStudy>): Partial<RemoteRecruitmentStudy> => {
  return {
    name: study.name,
    target: study.target,
    recruiter_name: study.recruiterName,
    reference_number: study.reference,
    center_ids: study.centerIds,
    target_date: study.targetDate
      ? dayjs(study.targetDate).format('YYYY-MM-DD')
      : typeof study.targetDate === 'object'
      ? null
      : undefined,
    status: study.status,
    active: study.active,
    display_name: study.displayName,
    summary: study.summary,
    details: study.details ? parseDetailsForSave(study.details) : undefined,
    age: study.age,
    sex: study.sex,
    header_image: study.image,
    visit_number: study.visitNumber,
    duration: study.duration,
    duration_unit: study.durationUnit,
    payment_type: study.paymentType,
    payment: study.payment,
    currency: study.currency,
    edc_study: study.edcStudy,
    table_variables: study.tableVariables,
    wash_out_active: study.washOutActive,
    wash_out_period: study.washOutPeriod,
    wash_out_period_type: study.washOutPeriodType,
    wash_out_zones: study.washOutZones,
    expected_duration: study.expectedDuration,
    expected_duration_type: study.expectedDurationType,
    study_zones: study.studyZones
  }
}

export interface RecruitmentStudiesSorter {
  field: keyof RecruitmentStudy
  order: SorterOrder
}

const sorterFields = {
  id: ['id']
}

interface FetchRecruitmentStudiesOptions {
  options?: {
    sorter?: RecruitmentStudiesSorter
    search?: string
    limit?: number
    offset?: number
    filters?: Record<string, string[]>
  }
}

interface FetchRecruitmentStudiesResponse {
  results: RemoteRecruitmentStudy[]
  count: number
  count_all: number
  count_archived: number
  count_draft: number
  count_ended: number
  count_recruiting: number
}

interface RecruitmentStudyMeta {
  studiesCount: number
  allStudiesCount: number
  recruitingStudiesCount: number
  draftStudiesCount: number
  endedStudiesCount: number
  archivedStudiesCount: number
}

interface FetchRecruitmentStudiesResponseHandlers {
  onSuccess?: (studies: RecruitmentStudy[], meta: RecruitmentStudyMeta) => void
  onRequestError?: (code: number) => void
}

export const fetchRecruitmentStudies = (
  { options }: FetchRecruitmentStudiesOptions,
  responseHandlers?: FetchRecruitmentStudiesResponseHandlers
) => {
  const sorter = prepareSorter<typeof sorterFields, RecruitmentStudiesSorter>(sorterFields, options.sorter, 'id')
  const query = {
    ordering: sorter,
    search: options?.search?.toLowerCase(),
    limit: options?.limit,
    offset: options?.offset,
    status: options?.filters?.status.length ? options.filters.status.join(',') : undefined
  }
  const { req, cancel } = fetchApi.get<FetchRecruitmentStudiesResponse>('recruitment/studies', query)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchRecruitmentStudiesResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(
        body.results.map(study => parseRemoteRecruitmentStudy(study)),
        {
          allStudiesCount: body.count_all || 0,
          studiesCount: body.count || 0,
          recruitingStudiesCount: body.count_recruiting || 0,
          draftStudiesCount: body.count_draft || 0,
          endedStudiesCount: body.count_ended || 0,
          archivedStudiesCount: body.count_archived || 0
        }
      )
    }
  })

  return cancel
}

interface CreateRecruitmentStudyResponseHandlers {
  onSuccess?: ({ uuid }: CreateRecruitmentStudyResponse) => void
  onRequestError?: (code: number) => void
  onReferenceTaken?: () => void
  onTooManyCenters?: () => void
}

interface CreateRecruitmentStudyResponse {
  uuid: string
}

export const createRecruitmentStudy = (
  study: RecruitmentStudy,
  responseHandlers?: CreateRecruitmentStudyResponseHandlers
) => {
  const { req, cancel } = fetchApi.post<CreateRecruitmentStudyResponse>(
    'recruitment/studies',
    parseRecruitmentStudyForSave(study)
  )

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<CreateRecruitmentStudyResponseHandlers>(
        {
          [BackendError.STUDY_REFERENCE_ALREADY_EXISTS]: 'onReferenceTaken',
          [BackendError.STUDY_CENTERS_LIMIT_REACHED]: 'onTooManyCenters'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body)
    }
  })

  return cancel
}

interface UpdateRecruitmentStudyResponseHandlers {
  onSuccess?: (study: RecruitmentStudy) => void
  onRequestError?: (code: number) => void
  onReferenceTaken?: () => void
  onTooManyCenters?: () => void
  onCenterCannotBeRemoved?: () => void
  onPaymentConfigDisabled?: () => void
  onError?: () => void
}
export const updateRecruitmentStudy = (
  { studyId, ...study }: Partial<RecruitmentStudy> & { studyId: string },
  responseHandlers?: UpdateRecruitmentStudyResponseHandlers
) => {
  const { req, cancel } = fetchApi.patch<RemoteRecruitmentStudy>(
    `recruitment/studies/${studyId}`,
    parseRecruitmentStudyForSave(study)
  )

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<UpdateRecruitmentStudyResponseHandlers>(
        {
          [BackendError.STUDY_REFERENCE_ALREADY_EXISTS]: 'onReferenceTaken',
          [BackendError.STUDY_CENTERS_LIMIT_REACHED]: 'onTooManyCenters',
          [BackendError.STUDY_CENTER_CANT_DELETE_USED]: 'onCenterCannotBeRemoved',
          [BackendError.RECRUITMENT_ONGOING_PAYMENT_CONFIG_DISABLED]: 'onPaymentConfigDisabled'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteRecruitmentStudy(body))
    }
  })

  return cancel
}

interface FetchRecruitmentStudyOptions {
  studyId: string
}

interface FetchRecruitmentStudyResponseHandlers {
  onSuccess?: (study: RecruitmentStudy) => void
  onRequestError?: (code: number) => void
}

export const fetchRecruitmentStudy = (
  { studyId }: FetchRecruitmentStudyOptions,
  responseHandlers?: FetchRecruitmentStudyResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<RemoteRecruitmentStudy>(`recruitment/studies/${studyId}`)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchRecruitmentStudyResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteRecruitmentStudy(body))
    }
  })

  return cancel
}

export interface RecruitmentVariable {
  id: number
  title: string
  variable: string
  type: QuestionType
}

interface FetchRecruitmentVariableResponseHandlers {
  onSuccess?: (variables: RecruitmentVariable[]) => void
  onRequestError?: (code: number) => void
}

export const fetchRecruitmentVariables = (
  { studyId }: { studyId: string },
  responseHandlers: FetchRecruitmentVariableResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<RecruitmentVariable[]>(`recruitment/${studyId}/variables`)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchRecruitmentVariableResponseHandlers>({}, error, responseHandlers, status)
    } else {
      responseHandlers.onSuccess(body)
    }
  })

  return cancel
}

interface RemoteRecruitmentStudySettings {
  active: boolean
  display_name: string
  summary: string
  details: string
  header_image_url: string
  age: string
  sex: string
  duration: string
  duration_unit: RecruitmentDurationUnit
  visit_number: number
  center_name: string
}

export interface RecruitmentStudySettings {
  active: boolean
  displayName: string
  summary: string
  details: ContentState
  imageUrl: string
  age: string
  sex: string
  duration: string
  durationUnit: RecruitmentDurationUnit
  visitNumber: number
  centerName: string
}

const parseRemoteRecruitmentStudySettings = (
  remoteStudy: RemoteRecruitmentStudySettings
): RecruitmentStudySettings => ({
  active: remoteStudy.active,
  displayName: remoteStudy.display_name,
  summary: remoteStudy.summary,
  details: remoteStudy.details && convertFromRaw(JSON.parse(remoteStudy.details)),
  imageUrl: remoteStudy.header_image_url,
  age: remoteStudy.age,
  sex: remoteStudy.sex,
  duration: remoteStudy.duration,
  durationUnit: remoteStudy.duration_unit,
  visitNumber: remoteStudy.visit_number,
  centerName: remoteStudy.center_name
})

interface FetchRecruitmentStudySettingsResponseHandlers {
  onSuccess?: (data: RecruitmentStudySettings) => void
  onRequestError?: (code: number) => void
}

export const fetchRecruitmentStudySettings = (
  { qrCode }: { qrCode: string },
  responseHandlers?: FetchRecruitmentStudySettingsResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<RemoteRecruitmentStudySettings>(`recruitment/fulfillment/landing-page/${qrCode}`)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchRecruitmentStudySettingsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteRecruitmentStudySettings(body))
    }
  })

  return cancel
}

interface FetchZonesResponseHandlers {
  onSuccess?: (zones: string[]) => void
  onRequestError?: (code: number) => void
}

export const fetchZones = (responseHandlers?: FetchZonesResponseHandlers) => {
  const { req, cancel } = fetchApi.get<string[]>('recruitment/zones')

  req.then(({ error, body }) => {
    if (error) {
      createErrorsHandlers<FetchZonesResponseHandlers>({}, error, responseHandlers)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body)
    }
  })

  return cancel
}
