import { isEqual } from 'lodash'

import {
  BaseQuestionAnswer,
  DateFormat,
  DateTimeSubtype,
  OptionsRenderTypes,
  Question,
  QuestionType,
  RecordVariable
} from '../requests'
import { getDateTimeSubtypeFormat, getSwitchableDateType, parseInputDateTime } from './dateTime'

export const isAnswerValueEmpty = (questionType: QuestionType, value: BaseQuestionAnswer['value']) => {
  if (questionType === QuestionType.Radio) return !!(!value?.selected && !(value?.other || value?.other === ''))
  if (questionType === QuestionType.Checkbox)
    return !!(!value?.selections?.length && !(value?.other || value?.other === ''))
  if (questionType === QuestionType.Dropdown) return !value?.selected
  if (questionType === QuestionType.File) return !value?.length && !value?.uid
  if ([QuestionType.Number, QuestionType.Rating, QuestionType.Slider].includes(questionType))
    return !!(!value && typeof value !== 'number')
  if (!value) return true
  return false
}

export const areAnswersEqual = (
  questionType: QuestionType,
  previousValue: BaseQuestionAnswer['value'],
  newValue: BaseQuestionAnswer['value']
) => {
  if (questionType === QuestionType.Radio) {
    return previousValue?.selected === newValue?.selected && previousValue?.other === newValue?.other
  }

  if (questionType === QuestionType.Checkbox) {
    return isEqual(previousValue?.selections, newValue?.selections) && previousValue?.other === newValue?.other
  }

  if (questionType === QuestionType.Dropdown) {
    return previousValue?.selected === newValue?.selected
  }

  return previousValue === newValue
}

// detect select types and return answers array, all other types are treated as text
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const renderSimpleAnswer = (answer: any) => {
  if (answer == null) return '-'

  if (typeof answer === 'object') {
    const answerArray = answer.selections || []
    if (answer.selected) answerArray.push(answer.selected)
    if (answer.other) answerArray.push(answer.other)
    return answerArray.join(', ')
  }

  return answer
}

type QuestionForRender = RecordVariable | Question

export const renderAnswer = (
  question: QuestionForRender,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  answer: any,
  dateFormat: DateFormat,
  optionsRenderType: OptionsRenderTypes = null
) => {
  const valueToShowBasedOnRenderType = optionsRenderType === OptionsRenderTypes.NUMBER ? 'numberLabel' : 'label'

  if (answer === null || answer === undefined || answer === '') return '-'
  if (answer === '.hidden') return '.hidden'

  if (
    question.type === QuestionType.Text ||
    question.type === QuestionType.Number ||
    question.type === QuestionType.Calcul
  )
    return answer
  if (question.type === QuestionType.Radio || question.type === QuestionType.Dropdown) {
    const option = question.config.options.find(option => option.value === answer?.selected)
    return option ? option[valueToShowBasedOnRenderType] : answer?.other
  }
  if (question.type === QuestionType.Checkbox) {
    return [
      ...question.config.options.map(option =>
        answer?.selections?.includes(option.value) ? option[valueToShowBasedOnRenderType] : null
      ),
      answer?.other
    ]
      ?.filter(v => !!v)
      .join(', ')
  }
  if (question.type === QuestionType.DateTime) {
    const subtypeFormat =
      question.config.subtype === DateTimeSubtype.SwitchableDate
        ? getSwitchableDateType(answer)
        : (question.config.subtype as DateTimeSubtype)
    return parseInputDateTime(answer, subtypeFormat).format(getDateTimeSubtypeFormat(dateFormat)[subtypeFormat])
  }
  if (question.type === QuestionType.Slider || question.type === QuestionType.Rating) {
    return `${answer} (${question.config.minValue}-${question.config.maxValue})`
  }

  return '-'
}
