import dayjs from 'dayjs'
import { changeLocale as changeGatsbyLocale } from 'gatsby-plugin-react-intl'
import { zones } from 'moment-timezone/data/meta/latest.json'

import { RequestError, SorterOrder } from '../requests'
import { AvailableLocales } from '../requests/generalSettings/users'

export interface Identifiable {
  id?: string
  tempId?: string
}

export const sameId = (item1: Identifiable, item2: Identifiable) =>
  (item1.id && item1.id === item2.id) || (item1.tempId && item1.tempId === item2.tempId)

export const proxyMinioToLocalhost = (url: string, removeParams = false) => {
  if (!url) return null
  const backendUrl = process.env.GATSBY_BACKEND_API_URL
  // 127.0.0.1 is used for e2e tests
  if (!backendUrl.includes('http://localhost') && !backendUrl.includes('http://127.0.0.1')) return url
  const newUrl = url.replace('http://minio', 'http://localhost')
  return removeParams ? newUrl.split('?')[0] : newUrl
}

// eslint-disable-next-line
export const noop = () => {}

export const localeFromPath = () => {
  if (typeof window === 'undefined') return AvailableLocales.En
  switch (window.location.pathname.split('/')[1]) {
    case 'fr':
      return AvailableLocales.Fr
    case 'it':
      return AvailableLocales.It
    case 'pl':
      return AvailableLocales.Pl
    case 'pt':
      return AvailableLocales.Pt
    case 'de':
      return AvailableLocales.De
    case 'es':
      return AvailableLocales.Es
    case 'ja':
      return AvailableLocales.Ja
    case 'zh':
      return AvailableLocales.Zh
    default:
      return AvailableLocales.En
  }
}

// not totally reliable (one timezone can have multiple countries) but better than nothing
export const localeFromTimezone = () => {
  const tz = dayjs.tz.guess() as keyof typeof zones
  if (Object.keys(zones).includes(tz)) {
    return zones[tz].countries[0]
  }
  return null
}

export const changeLocale = (newLocale: AvailableLocales) => {
  if (Object.values(AvailableLocales).includes(newLocale as AvailableLocales) && localeFromPath() !== newLocale)
    changeGatsbyLocale(newLocale, `${window.location.pathname.slice(3)}${window.location.hash}`)
}

type CreateErrorsHandlers<T> =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { [key in keyof T]?: any } & { onRequestError?: (code?: number) => void } & { onError?: () => void }

export const createErrorsHandlers = <T extends CreateErrorsHandlers<T>>(
  config: { [key: number]: keyof T },
  error: RequestError,
  responseHandlers: T,
  status?: number
) => {
  if (status && responseHandlers[config[status]]) {
    responseHandlers[config[status]]()
    if (responseHandlers.onError) responseHandlers.onError()
  } else if (config[error.code] && responseHandlers[config[error.code]]) {
    responseHandlers[config[error.code]]()
    if (responseHandlers.onError) responseHandlers.onError()
  } else if (responseHandlers.onRequestError) {
    responseHandlers.onRequestError(error.code || status)
    if (responseHandlers.onError) responseHandlers.onError()
  } else {
    throw error
  }
}

export const execCopyCommand = (value: string) => {
  const el = document.createElement('textarea')
  el.value = value
  // Make sure it's hidden and not focusable
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

export const setBodyOverflow = (overflow: 'auto' | 'hidden') => {
  document.getElementsByTagName('body')[0].style.overflow = overflow
}

export const addHttpPrefix = (url: string) => `${url.toLowerCase().substring(0, 4) !== 'http' ? 'http://' : ''}${url}`

export const enumToOptions = <T extends Record<string, string>>(
  values: T,
  intl: (v: string) => string,
  filter?: (v: string) => boolean
) => Object.values(values).map(v => ({ label: intl(v.toLowerCase()), value: v, disabled: filter && filter(v) }))

export const questionIdWithMeasureId = (id: string, measure?: string) => `${id}${measure ? `-${measure}` : ''}`

export const getParsedHash = () => {
  if (typeof window === 'undefined') return []
  return window.location.hash.slice(1).split('?')[0].split(',')
}

export const getRecruitmentCodeAndEmailFromHash = () => {
  if (typeof window === 'undefined') return [undefined, undefined]

  const hashArray = window.location.hash.slice(1).split(',')
  const qrCode = hashArray.shift()
  const email = hashArray.join(',')
  return [qrCode, email]
}

export const paramsToQueryString = (params: { [key: string]: string | string[] | boolean }) =>
  Object.entries(params)
    .reduce((acc, [key, value]) => {
      const flatValue = Array.isArray(value) ? value.join(',') : value
      if (flatValue) acc.push(`${key}=${flatValue}`)
      return acc
    }, [])
    .join('&')

export type TableRecord<T extends {}> = T & { key: string }

export const createTableRecords = <T>(data: (T & { id: string })[]) =>
  data &&
  data.map(row => ({
    ...row,
    key: row.id
  }))

interface GenericSorter {
  field: string | number | symbol
  order: SorterOrder
}

const reversedInitialSorterFields = ['id', 'timestamp']

export const prepareSorter = <T extends Record<string, string[]>, U extends GenericSorter>(
  mapping: T,
  sorter: U,
  initial?: U['field']
) => {
  const mappedInitial = (mapping?.[initial as keyof T] || initial) as string
  const orderedInitial =
    !!mappedInitial && `${reversedInitialSorterFields.includes(mappedInitial) ? '-' : ''}${mappedInitial}`
  if (!sorter) return initial ? [orderedInitial] : undefined

  let fields: string[] = [...(mapping?.[sorter.field as keyof T] || [])]
  if (!fields.length) fields = [sorter.field as string]
  if (initial && sorter.field !== initial) fields.push(orderedInitial)

  return fields.map(field => `${sorter.order === SorterOrder.Ascend ? '' : '-'}${field}`)
}

export enum ViewOptionsIds {
  MONITORING_QUERIES_DASHBOARD = 'monitoring.queries.dashboard',
  MONITORING_QUERIES_TABLE = 'monitoring.queries.table',
  MONITORING_REVIEWS_DASHBOARD = 'monitoring.reviews.dashboard',
  MONITORING_REVIEWS_TABLE = 'monitoring.reviews.table',
  MONITORING_SDV_DASHBOARD = 'monitoring.sdv.dashboard',
  MONITORING_SDV_TABLE = 'monitoring.sdv.table',
  MONITORING_MISSING_DASHBOARD = 'monitoring.missing.dashboard',
  MONITORING_MISSING_TABLE = 'monitoring.missing.table'
}

export const getInitials = (fullName: string) => {
  if (!fullName) return ''

  // remove parenthesis and it's content as sometimes we add additional information in them
  const name = fullName.replace(/\([^)]*\)/g, '')

  const [firstName, lastName] = name.split(' ')
  return `${firstName?.charAt(0) || ''}${lastName?.charAt(0) || ''}`.toUpperCase()
}

export const getFirstName = (fullName: string) => {
  // remove parenthesis and it's content as sometimes we add additional information in them
  const name = fullName.replace(/\([^)]*\)/g, '')

  const [firstName] = name.split(' ')
  return firstName || ''
}

export const downloadBlob = (file: Blob, fileName: string, type = 'text/plain') => {
  const tmp = document.createElement('a')
  tmp.setAttribute('href', window.URL.createObjectURL(file))
  tmp.setAttribute('download', fileName)
  tmp.dataset.downloadurl = [type, tmp.download, tmp.href].join(':')
  tmp.draggable = true
  tmp.classList.add('dragout')
  tmp.click()
}

export const convertXMLStringToblob = (xmlstring: string) => {
  // Convert xml string to base64data
  const xmlval = new DOMParser().parseFromString(xmlstring, 'application/xml')
  const base64Data = window.btoa(new XMLSerializer().serializeToString(xmlval))

  // Convert base64data to blob
  const byteCharacters = window.atob(base64Data)
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i += 1) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  const byteArray = new Uint8Array(byteNumbers)
  return new Blob([byteArray], { type: 'application/xml' })
}

export const documentHeight = () => {
  const body = document.body
  const html = document.documentElement

  return Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)
}

export const numberWithSpaces = (x: number) => {
  if (x === null) {
    return ''
  }
  const parts = x.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  return parts.join('.')
}
