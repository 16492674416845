/* eslint-disable camelcase */
import { createErrorsHandlers } from '../utils'
import { Feature } from './Feature'
import { BackendError } from './RequestError'
import { EproStatus } from './epros'
import { fetchApi } from './fetchApi'
import { downloadFile } from './file'
import { Condition, prepareConditionForSave } from './forms'
import { SubjectStatus } from './subjectRepository'

export type SelectionKeys = (string | number)[]

export enum ExportFileFormat {
  Pdf = 'PDF',
  Csv = 'CSV',
  Excel = 'XLSX'
}

export enum ExportDataFormat {
  Labels = 'LABELS',
  Values = 'VALUES'
}

export enum ExportType {
  Ecrf = 'ECRF',
  Inclusion = 'INCLUSION',
  Epro = 'EPRO',
  EproRecord = 'EPRO_RECORD',
  EproList = 'EPRO_LIST',
  EcrfBlankPdf = 'ECRF_BLANK_PDF',
  EproBlankPdf = 'EPRO_BLANK_PDF',
  Econsent = 'ECONSENT',
  EconsentBlankPdf = 'ECONSENT_BLANK_PDF',
  EconsentPdf = 'ECONSENT_PDF',
  Subject = 'SUBJECT',
  Subjects = 'SUBJECTS',
  Recruitment = 'RECRUITMENT',
  Payments = 'PAYMENTS',
  PaymentOrders = 'PAYMENT_ORDERS'
}

export type ExportDataCommonOptions = {
  exportDataFormat: ExportDataFormat
  exportFileFormat: ExportFileFormat
  exportFiles: boolean
  rmInSeparateFile?: boolean
  domainsExport?: boolean
  selectedBlockIds?: Set<string>
}

type ExportDataOptions = ExportDataCommonOptions & {
  exportType?: ExportType
  locale?: string
  studyId: string
  search?: string
  status?: string[]
  center?: string[]
  investigator?: string[]
  recordsIds?: SelectionKeys
} & (
    | { feature: Feature.Ecrf }
    | { feature: Feature.Epro; eproId?: string; eproStatus?: EproStatus }
    | { feature: Feature.Econsent }
  ) // we use this only for blank builder pdf export, so there is no need for more options

const detectExportType = (feature: Feature, single: boolean) => {
  switch (feature) {
    case Feature.Epro:
      return single ? ExportType.EproRecord : ExportType.Epro
    case Feature.Ecrf:
      return single ? ExportType.Inclusion : ExportType.Ecrf
    default:
      return null
  }
}

const parseRecordsForExport = (records: SelectionKeys, single: boolean) => {
  if (!records?.length || (single && records?.length !== 1) || (!single && records?.length === 1)) return undefined
  return single ? (records[0] as string) : (records as string[])
}

interface ExportDataResponse {
  export_uuid: string
}

export interface ExportDataResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
  onNoInclusions?: () => void
  onNoStructureError?: () => void
  onError?: () => void
}

export const canceller: {
  cancelExportRequest: () => void
  cancelDownload: () => void
} = {
  cancelExportRequest: () => null,
  cancelDownload: () => null
}

export const exportData = (options: ExportDataOptions, responseHandlers?: ExportDataResponseHandlers) => {
  const {
    studyId,
    exportDataFormat,
    exportFileFormat,
    exportFiles,
    selectedBlockIds,
    exportType,
    feature,
    locale,
    search,
    status,
    center,
    investigator
  } = options

  const formatSuffix = feature === Feature.Econsent && exportFileFormat === ExportFileFormat.Pdf ? '/pdf' : ''
  const path = `exports/${feature.toLowerCase()}${formatSuffix}`
  const currentExportType =
    exportType ||
    detectExportType(
      feature,
      (options.feature === Feature.Ecrf && options.recordsIds?.length === 1) ||
        (options.feature === Feature.Epro && options.recordsIds?.length === 1)
    )

  let body: Record<string, string | boolean | string[]> = {
    export_data_format: exportDataFormat,
    export_files: exportFiles,
    question_ids: selectedBlockIds?.size ? [...selectedBlockIds] : undefined,
    export_type: currentExportType,
    file_format: exportFileFormat,
    language: locale,
    search: search || undefined,
    status,
    study_center_ids: center,
    investigator_id: investigator?.length ? investigator.join(',') : undefined,
    record_id: parseRecordsForExport(options.recordsIds, true),
    record_ids: parseRecordsForExport(options.recordsIds, false)
  }

  switch (options.feature) {
    case Feature.Ecrf:
      body = {
        ...body,
        export_type: currentExportType,
        rm_in_separate_file: options.rmInSeparateFile,
        domains_export: options.domainsExport
      }
      break
    case Feature.Epro:
      body = {
        ...body,
        epro_id: options.eproId ?? undefined
      }
      break
  }

  const { req, cancel } = fetchApi.post<ExportDataResponse>(path, body, { studyId })
  canceller.cancelExportRequest = cancel
  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<ExportDataResponseHandlers>(
        {
          [BackendError.EXPORT_NO_INCLUSIONS]: 'onNoInclusions',
          [BackendError.ECRF_NOT_EXISTS]: 'onNoStructureError',
          [BackendError.ECONSENT_NOT_EXISTS]: 'onNoStructureError',
          [BackendError.EPRO_NOT_EXISTS]: 'onNoStructureError'
        },
        error,
        responseHandlers,
        status
      )
    } else {
      canceller.cancelDownload = downloadExportData({ exportId: body.export_uuid, studyId }, responseHandlers)
    }
  })

  return () => {
    canceller.cancelDownload()
    canceller.cancelExportRequest()
  }
}

interface DownloadExportDataOptions {
  exportId: string
  studyId?: string
}

interface DownloadExportDataResponse {
  report_url: string
}

interface DownloadExportDataResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const downloadExportData = (
  { exportId, studyId }: DownloadExportDataOptions,
  responseHandlers?: DownloadExportDataResponseHandlers
) => {
  let cancelCheckStatusRequest: () => void = () => null
  let pingServerTimeout: number = null

  const checkStatus = () => {
    const { req, cancel } = fetchApi.get<DownloadExportDataResponse>(`exports/status/${exportId}`, {}, { studyId })
    cancelCheckStatusRequest = cancel

    return req.then(({ error, body, status }) => {
      if (status === 200) return Promise.resolve(body.report_url)
      if (status === 202) return Promise.resolve(null)

      if (error && responseHandlers?.onRequestError) {
        responseHandlers.onRequestError(status)
      }

      return Promise.reject()
    })
  }

  const pingServerForFile = (onReady: (url: string) => void) => {
    checkStatus().then(url => {
      if (url) {
        onReady(url)
      } else {
        pingServerTimeout = window.setTimeout(() => pingServerForFile(onReady), 1000)
      }
    })
  }

  pingServerForFile(fileUrl => {
    if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
    downloadFile(fileUrl)
  })

  return () => {
    cancelCheckStatusRequest()
    window.clearTimeout(pingServerTimeout)
  }
}

interface ExportAnalyticsTableResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

type ExportAnalyticsTableOptions = {
  exportFileFormat: ExportFileFormat
  studyId: string
  tableId: string
}

export const exportAnalyticsTable = (
  { exportFileFormat, studyId, tableId }: ExportAnalyticsTableOptions,
  responseHandlers?: ExportAnalyticsTableResponseHandlers
) => {
  const path = 'exports/analytics'
  const { req, cancel } = fetchApi.post<ExportDataResponse>(
    path,
    { table_id: tableId, file_format: exportFileFormat },
    { studyId }
  )
  canceller.cancelExportRequest = cancel
  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<ExportAnalyticsTableResponseHandlers>({}, error, responseHandlers, status)
    } else {
      canceller.cancelDownload = downloadExportData({ exportId: body.export_uuid, studyId }, responseHandlers)
    }
  })

  return () => {
    canceller.cancelDownload()
    canceller.cancelExportRequest()
  }
}

interface ExportStudyAuditTrailsResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const exportStudyAuditTrails = (
  { studyId }: { studyId: string },
  responseHandlers?: ExportStudyAuditTrailsResponseHandlers
) => {
  const { req, cancel } = fetchApi.post<ExportDataResponse>('exports/audit_trails/study', {}, { studyId })
  canceller.cancelExportRequest = cancel
  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<ExportStudyAuditTrailsResponseHandlers>({}, error, responseHandlers, status)
    } else {
      canceller.cancelDownload = downloadExportData({ exportId: body.export_uuid, studyId }, responseHandlers)
    }
  })

  return () => {
    canceller.cancelDownload()
    canceller.cancelExportRequest()
  }
}

interface ExportGlobalAuditTrailsResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const exportGlobalAuditTrails = (responseHandlers?: ExportGlobalAuditTrailsResponseHandlers) => {
  const { req, cancel } = fetchApi.post<ExportDataResponse>('exports/audit_trails/global', {})
  canceller.cancelExportRequest = cancel
  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<ExportStudyAuditTrailsResponseHandlers>({}, error, responseHandlers, status)
    } else {
      canceller.cancelDownload = downloadExportData({ exportId: body.export_uuid }, responseHandlers)
    }
  })

  return () => {
    canceller.cancelDownload()
    canceller.cancelExportRequest()
  }
}

interface ExportTemplateResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const exportTemplate = (
  { templateId }: { templateId: string },
  responseHandlers?: ExportTemplateResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<ExportDataResponse>(`exports/templates/${templateId}`)
  canceller.cancelExportRequest = cancel
  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<ExportTemplateResponseHandlers>({}, error, responseHandlers, status)
    } else {
      canceller.cancelDownload = downloadExportData({ exportId: body.export_uuid }, responseHandlers)
    }
  })

  return () => {
    canceller.cancelDownload()
    canceller.cancelExportRequest()
  }
}

export enum ExportFileStatus {
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
  Error = 'ERROR'
}
interface RemoteFileToDownload {
  export_file_format: string
  exported_by_name: string
  uuid: string
  report_size: string
  date_added: string
  status: ExportFileStatus
}

export interface FileToDownload {
  fileFormat: string
  userName: string
  uuid: string
  size: string
  dateAdded: Date
  status: ExportFileStatus
}

const parseFileToDownload = (remoteFile: RemoteFileToDownload) => ({
  fileFormat: remoteFile.export_file_format,
  userName: remoteFile.exported_by_name,
  uuid: remoteFile.uuid,
  size: remoteFile.report_size,
  dateAdded: remoteFile.date_added ? new Date(remoteFile.date_added) : undefined,
  status: remoteFile.status
})

type FetchExportListResponse = { results: RemoteFileToDownload[] }

interface FetchExportListResponseHandlers {
  onSuccess?: (files: FileToDownload[]) => void
  onRequestError?: (code: number) => void
}

interface FetchExportListOptions {
  studyId: string
  feature: Feature
  eproId?: string
}

export const fetchExportList = (
  { studyId, eproId, feature }: FetchExportListOptions,
  responseHandlers?: FetchExportListResponseHandlers
) => {
  const path = `exports/${feature.toLowerCase()}`
  const { req, cancel } = fetchApi.get<FetchExportListResponse>(path, { limit: 25, epro_id: eproId }, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchExportListResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.results.map(parseFileToDownload))
    }
  })

  return cancel
}

interface ExportSubjectRepositoryResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

type ExportSubjectRepositoryOptions = {
  exportFileFormat: ExportFileFormat
  search: string
  conditions: Condition[]
  subjects: SelectionKeys
  status: SubjectStatus
  exportFiles: boolean
}

export const exportSubjectRepository = (
  { exportFileFormat, exportFiles, search, conditions, status, subjects }: ExportSubjectRepositoryOptions,
  responseHandlers?: ExportSubjectRepositoryResponseHandlers
) => {
  const path = `exports/subject_repository`
  const query = {
    search: search || undefined,
    conditions: conditions?.map(prepareConditionForSave),
    subjects,
    export_type: subjects?.length === 1 ? ExportType.Subject : ExportType.Subjects,
    export_data_format: ExportDataFormat.Labels,
    export_files: exportFiles,
    status: status || undefined,
    file_format: exportFileFormat
  }
  const { req, cancel } = fetchApi.post<ExportDataResponse>(path, query)
  canceller.cancelExportRequest = cancel
  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<ExportSubjectRepositoryResponseHandlers>({}, error, responseHandlers, status)
    } else {
      canceller.cancelDownload = downloadExportData({ exportId: body.export_uuid }, responseHandlers)
    }
  })

  return () => {
    canceller.cancelDownload()
    canceller.cancelExportRequest()
  }
}

interface ExportCandidatesResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

type ExportCandidatesOptions = {
  exportFileFormat: ExportFileFormat
  search: string
  records: SelectionKeys
  status: string[]
  exportFiles: boolean
  studyId: string
}

export const exportCandidates = (
  { exportFileFormat, exportFiles, search, status, records, studyId }: ExportCandidatesOptions,
  responseHandlers?: ExportCandidatesResponseHandlers
) => {
  const path = `exports/recruitment/${studyId}`
  const query = {
    search: search || undefined,
    records,
    export_type: ExportType.Recruitment,
    export_data_format: ExportDataFormat.Labels,
    export_files: exportFiles,
    status: status?.length ? status.join(',') : undefined,
    file_format: exportFileFormat
  }
  const { req, cancel } = fetchApi.post<ExportDataResponse>(path, query)
  canceller.cancelExportRequest = cancel
  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<ExportCandidatesResponseHandlers>({}, error, responseHandlers, status)
    } else {
      canceller.cancelDownload = downloadExportData({ exportId: body.export_uuid }, responseHandlers)
    }
  })

  return () => {
    canceller.cancelDownload()
    canceller.cancelExportRequest()
  }
}

interface ExportTestsResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

interface ExportTestsOptions {
  search: string
  records: SelectionKeys
  centers: string[]
  projectId: string
  exportFiles: boolean
}

export const exportTests = (
  { search, centers, records, projectId, exportFiles }: ExportTestsOptions,
  responseHandlers?: ExportTestsResponseHandlers
) => {
  const path = `exports/side_by_side/${projectId}`
  const query = {
    search: search || undefined,
    records,
    center_ids: centers,
    export_files: exportFiles
  }
  const { req, cancel } = fetchApi.post<ExportDataResponse>(path, query)
  canceller.cancelExportRequest = cancel
  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<ExportCandidatesResponseHandlers>({}, error, responseHandlers, status)
    } else {
      canceller.cancelDownload = downloadExportData({ exportId: body.export_uuid }, responseHandlers)
    }
  })

  return () => {
    canceller.cancelDownload()
    canceller.cancelExportRequest()
  }
}

interface ExportPaymentsResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

type ExportPaymentsOptions = {
  exportFileFormat: ExportFileFormat
  search: string
  records: SelectionKeys
  status: string[]
  exportFiles: boolean
  studyId?: string
}

export const exportPayments = (
  { exportFileFormat, exportFiles, search, status, records, studyId }: ExportPaymentsOptions,
  responseHandlers?: ExportPaymentsResponseHandlers
) => {
  const path = `exports/${studyId ? `recruitment/${studyId}/` : ''}payments`
  const query = {
    search: search || undefined,
    records,
    export_type: ExportType.Payments,
    export_data_format: ExportDataFormat.Labels,
    export_files: exportFiles,
    status: status?.length ? status.join(',') : undefined,
    file_format: exportFileFormat
  }
  const { req, cancel } = fetchApi.post<ExportDataResponse>(path, query)
  canceller.cancelExportRequest = cancel
  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<ExportCandidatesResponseHandlers>({}, error, responseHandlers, status)
    } else {
      canceller.cancelDownload = downloadExportData({ exportId: body.export_uuid }, responseHandlers)
    }
  })

  return () => {
    canceller.cancelDownload()
    canceller.cancelExportRequest()
  }
}

export const exportPaymentOrders = (
  { exportFileFormat, exportFiles, search, status, records }: ExportPaymentsOptions,
  responseHandlers?: ExportPaymentsResponseHandlers
) => {
  const path = `exports/payment_orders`
  const query = {
    search: search || undefined,
    records,
    export_type: ExportType.PaymentOrders,
    export_data_format: ExportDataFormat.Labels,
    export_files: exportFiles,
    status: status?.length ? status.join(',') : undefined,
    file_format: exportFileFormat
  }
  const { req, cancel } = fetchApi.post<ExportDataResponse>(path, query)
  canceller.cancelExportRequest = cancel
  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<ExportCandidatesResponseHandlers>({}, error, responseHandlers, status)
    } else {
      canceller.cancelDownload = downloadExportData({ exportId: body.export_uuid }, responseHandlers)
    }
  })

  return () => {
    canceller.cancelDownload()
    canceller.cancelExportRequest()
  }
}
