/* eslint-disable camelcase */
import { BackendError } from '../RequestError'
import { fetchApi } from '../fetchApi'
import { fetchOpenEndpointFilesUrls } from '../file'
import {
  SurveyAnswer,
  SurveyData,
  SurveyRemoteBlocks,
  SurveyRemoteQuestionAnswer,
  deleteAnswer,
  deleteFileAnswer,
  downloadFileAnswer,
  fetchConditionalLogic,
  parseRemoteSurveyAnswer,
  parseSurveyBlocks,
  saveAnswer,
  saveFileAnswer
} from '../forms'
import { CandidateStatus, RemoteCandidateStatus, candidateStatusMapping } from './candidates'

interface RecruitmentAnswerFields {
  token: string
}

const recruitmentAnswerMapping = {
  token: 'token'
}

const getRecruitmentFilePath = () => `uploaded_files/recruitment/answer`
const getRecruitmentAnswerPath = (data: RecruitmentAnswerFields) => `recruitment/answer/${data.token}`

interface CheckRecruitmentSurveyResponseHandlers {
  onSuccess?: () => void
  onNotFound?: () => void
  onExpired?: () => void
  onUnauthorized?: () => void
  onRequestError?: (code?: number) => void
}

export const checkRecruitmentSurvey = (
  { token }: { token: string },
  responseHandlers?: CheckRecruitmentSurveyResponseHandlers
) => {
  const { req, cancel } = fetchApi.post(`recruitment/fulfillment/check/${token}`, {})
  req.then(({ error, status }) => {
    if (!error && responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
      return
    }

    if (status === 404 && responseHandlers?.onNotFound) {
      responseHandlers.onNotFound()
      return
    }

    if ((status === 403 || status === 401) && responseHandlers?.onUnauthorized) {
      responseHandlers.onUnauthorized()
      return
    }

    if (
      [
        BackendError.STUDY_STATUS_ENDED,
        BackendError.STUDY_STATUS_ARCHIVED,
        BackendError.STUDY_STATUS_SUSPENDED,
        BackendError.RECRUITMENT_STUDY_INACTIVE,
        BackendError.RECRUITMENT_STUDY_NOT_EXISTS,
        BackendError.RECRUITMENT_RECORD_NOT_EXISTS
      ].includes(error.code) &&
      responseHandlers?.onExpired
    ) {
      responseHandlers.onExpired()
      return
    }

    if (responseHandlers?.onRequestError) {
      responseHandlers.onRequestError()
    } else {
      throw error
    }
  })

  return cancel
}

interface VerifyRecruitmentSurveyResponse {
  status: RemoteCandidateStatus
}

interface VerifyRecruitmentSurveyResponseHandlers {
  onSuccess?: (status: CandidateStatus) => void
  onRequestError?: (code?: number) => void
}

export const verifyRecruitmentSurvey = (
  { token }: { token: string },
  responseHandlers?: VerifyRecruitmentSurveyResponseHandlers
) => {
  const { req, cancel } = fetchApi.post<VerifyRecruitmentSurveyResponse>(
    `recruitment/fulfillment/validate/${token}`,
    {}
  )
  req.then(({ error, body }) => {
    if (!error && responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(candidateStatusMapping[body.status])
      return
    }
    if (responseHandlers?.onRequestError) {
      responseHandlers.onRequestError()
    } else {
      throw error
    }
  })

  return cancel
}

export interface RemoteRecruitmentSurvey {
  subject_id: string
  blocks: SurveyRemoteBlocks[]
  survey_uuid: string
  has_schedule: boolean
}

interface FetchRecruitmentSurveyResponseHandlers {
  onSuccess?: (response: { surveyData: SurveyData; recruitmentId: string; hasSchedule: boolean }) => void
  onRequestError?: () => void
}

export const fetchRecruitmentSurvey = (
  { token }: { token: string },
  responseHandlers?: FetchRecruitmentSurveyResponseHandlers
) => {
  const url = `recruitment/fulfillment/${token}`
  const { req, cancel } = fetchApi.get<RemoteRecruitmentSurvey>(url)

  req.then(({ error, body }) => {
    if (!error && responseHandlers?.onSuccess) {
      responseHandlers.onSuccess({
        surveyData: {
          subjectId: body.subject_id,
          ...parseSurveyBlocks(body.blocks)
        },
        recruitmentId: body.survey_uuid,
        hasSchedule: body.has_schedule
      })
      return
    }

    if (responseHandlers?.onRequestError) {
      responseHandlers.onRequestError()
    } else {
      throw error
    }
  })

  return cancel
}

export const saveRecruitmentSurveyAnswer = saveAnswer<
  RecruitmentAnswerFields,
  SurveyRemoteQuestionAnswer,
  SurveyAnswer
>(getRecruitmentAnswerPath, recruitmentAnswerMapping, parseRemoteSurveyAnswer)

export const deleteRecruitmentSurveyAnswer = deleteAnswer<RecruitmentAnswerFields>(
  getRecruitmentAnswerPath,
  recruitmentAnswerMapping
)

export const saveRecruitmentSurveyFileAnswer = saveFileAnswer<
  RecruitmentAnswerFields,
  SurveyRemoteQuestionAnswer,
  SurveyAnswer
>(getRecruitmentFilePath, recruitmentAnswerMapping, parseRemoteSurveyAnswer)

export const deleteRecruitmentSurveyFileAnswer = deleteFileAnswer<RecruitmentAnswerFields>(
  getRecruitmentFilePath,
  recruitmentAnswerMapping
)

export const fetchRecruitmentFilesUrls = fetchOpenEndpointFilesUrls('uploaded_files/recruitment')

export const downloadRecruitmentFileAnswer = downloadFileAnswer('uploaded_files/recruitment/answer')

export const fetchRecruitmentConditionalLogic = fetchConditionalLogic<RecruitmentAnswerFields>(
  ({ token }) => `recruitment/conditional_logic/${token}`
)
