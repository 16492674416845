/* eslint-disable camelcase */
import dayjs from 'dayjs'
import { navigate } from 'gatsby-plugin-react-intl'
import queryString from 'query-string'
import React from 'react'

import { AccountType, accountUrlPrefix } from '.'
import { routes, routesWithoutAutoRedirect } from '../routes'
import { LocalStorageKey } from '../utils'
import { RequestError } from './RequestError'

const baseUrl = process.env.GATSBY_BACKEND_API_URL

type Token = string

let accessToken: Token = ''

export const getAccessToken = () => accessToken
export const setAccessTokenForSubject = (token: Token) => {
  accessToken = token
  setLastRequestTime()
  setLastLoginType(AccountType.Subject)
}

const SESSION_TIME_IN_MINUTES = 15

export const setLastRequestTime = () => localStorage.setItem(LocalStorageKey.LastRequestTime, Date.now().toString())

export const getSessionTimeLeft = () => {
  const lastRequestTime = parseInt(localStorage.getItem(LocalStorageKey.LastRequestTime), 10)
  const sessionEnd = dayjs(lastRequestTime).add(SESSION_TIME_IN_MINUTES, 'minutes')
  const timeUntilSessionEnd = dayjs.duration(sessionEnd.diff(dayjs()))

  return {
    minutes: timeUntilSessionEnd.minutes(),
    seconds: timeUntilSessionEnd.seconds()
  }
}

export const setLastLoginType = (type: AccountType) => localStorage.setItem(LocalStorageKey.LastLoginType, type)
const getLastAccountType = () =>
  localStorage.getItem(LocalStorageKey.LastLoginType) === AccountType.Subject ? AccountType.Subject : AccountType.User
const lastAccountTypeUrlPrefix = () => accountUrlPrefix(getLastAccountType())

export const setLastLocationPath = () => {
  const path = window.location.href
  const pathWithoutHostAndLanguage = path.split('/').slice(4).join('/')
  if (!routesWithoutAutoRedirect.some(route => pathWithoutHostAndLanguage.includes(route))) {
    localStorage.setItem(LocalStorageKey.LastLocationPath, `/${pathWithoutHostAndLanguage}`)
  }
}

enum RequestType {
  Basic = 1,
  Multipart = 2
}

enum ResponseType {
  Json = 1,
  Blob = 2
}

enum HttpMethod {
  GET = 'GET',
  PUT = 'PUT',
  POST = 'POST',
  PATCH = 'PATCH',
  DELETE = 'DELETE'
}

interface HeaderProps {
  studyId?: string
  language?: string
  qrCode?: string
}

interface RequestOptions {
  path: string
  // eslint-disable-next-line @typescript-eslint/ban-types
  body: object | FormData
  method: HttpMethod
  requestType: RequestType
  responseType: ResponseType
  query?: object
  headerProps?: HeaderProps
}

interface ApiResponse<T> {
  error: RequestError
  body: T
  status: number
}

type ApiRequest<T> = Promise<ApiResponse<T>>

interface SignInResponseHandlers {
  onSuccess: () => void
  onRequestError: () => void
  onInvalidCredentials: (attemptsLeft?: number) => void
  onInvalidMfaCode: (attemptsLeft?: number) => void
  onUserBlocked: (lockedSeconds?: number) => void
  onMfaCodeNeeded: (qrToken?: string) => void
  onPasswordResetNeeded: (token?: string) => void
}

const getUserRoleFromToken = (token: string) => {
  let userRoleId: string
  try {
    const parsedToken = JSON.parse(atob(token.split('.')[1]))
    userRoleId = parsedToken.role
  } catch (e) {
    userRoleId = null
  }

  return userRoleId
}

const checkKeyInGeneric = <T>(data: T, keyToFind: string) =>
  Object.entries(data).find(([key, value]) => key === keyToFind && value)

export type DataMapping<T> = { [key in keyof Partial<T>]: string }

export const mapData = <T>(dataMapping: DataMapping<T>, data: T) =>
  Object.entries(dataMapping).reduce(
    (acc, [key, value]) => {
      // if we have studyId BE doesn't want token in payload
      if (key === 'token' && checkKeyInGeneric(data, 'studyId')) return acc

      acc[value as string] = data[key as keyof T]
      return acc
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    {} as { [key: string]: any }
  )

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapFetchedData = <T>(dataMapping: DataMapping<T>, data: any) =>
  Object.entries(dataMapping).reduce((acc, [key, value]) => {
    acc[key as keyof T] = data[value as T[keyof T]]
    return acc
  }, {} as T)

class Fetch {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private refreshAccessTokenPromise: Promise<any> = null

  async signIn(
    options: { email: string; password: string; code?: string; qrToken?: string; accountType: AccountType },
    responseHandlers: SignInResponseHandlers
  ) {
    const url = `${accountUrlPrefix(options.accountType)}/auth/token`
    const { req } = options.qrToken
      ? this.post(`${url}/code`, {
          email: options.email.toLowerCase(),
          password: options.password,
          code: options.code,
          ephemeral_token: options.qrToken
        })
      : this.post(url, {
          email: options.email.toLowerCase(),
          password: options.password
        })

    setLastRequestTime()
    setLastLoginType(options.accountType)

    return (
      req as ApiRequest<{
        access?: string
        attempts_left?: number
        lock_time?: number
        ephemeral_token?: string
        reset_token?: string
      }>
    ).then(({ error, body, status }) => {
      if (error) {
        if (status === 400) responseHandlers.onInvalidMfaCode(body?.attempts_left)
        else if (status === 401) responseHandlers.onInvalidCredentials(body?.attempts_left)
        else if (status === 403) responseHandlers.onUserBlocked(body?.lock_time)
        else responseHandlers.onRequestError()
      } else if (body.reset_token) {
        responseHandlers.onPasswordResetNeeded(body.reset_token)
      } else if (body.ephemeral_token) {
        responseHandlers.onMfaCodeNeeded(body.ephemeral_token)
      } else {
        accessToken = body.access
        responseHandlers.onSuccess()
      }
    })
  }

  async refreshAccessToken() {
    const urlParams = new URLSearchParams(window.location.search)
    const urlRefreshToken = urlParams.get('refresh_token')

    if (urlRefreshToken) {
      accessToken = urlParams.get('token')
      setLastRequestTime()
      setLastLoginType(AccountType.User)
    }

    if (this.refreshAccessTokenPromise) return this.refreshAccessTokenPromise

    this.refreshAccessTokenPromise = fetch(
      this.getUrl(`${lastAccountTypeUrlPrefix()}/auth/refresh`),
      this.getRequestOptions(urlRefreshToken ? { token: urlRefreshToken } : {}, HttpMethod.POST, RequestType.Basic)
    ).then(async res => {
      this.refreshAccessTokenPromise = null
      if (res.ok) {
        const { access } = await res.json()
        accessToken = access

        if (urlRefreshToken) {
          navigate(routes.studies, { replace: true })
        }

        return {
          role: getUserRoleFromToken(access)
        }
      }
      accessToken = null

      setLastLocationPath()
      if (getSessionTimeLeft().seconds < 0) navigate(routes.sessionTimeout(getLastAccountType()))
      else navigate(routes.signIn(getLastAccountType()))

      return null
    })

    return this.refreshAccessTokenPromise
  }

  signOut(responseHandlers: { onSuccess: () => void; onRequestError: () => void }) {
    const { req } = this.post(`${lastAccountTypeUrlPrefix()}/auth/logout`, {})

    req.then(({ error }) => {
      if (error) responseHandlers.onRequestError()
      else {
        accessToken = null
        responseHandlers.onSuccess()
      }
    })
  }

  post<T>(url: string, body: object, headerProps?: HeaderProps) {
    return this.request<T>({
      path: url,
      body,
      method: HttpMethod.POST,
      requestType: RequestType.Basic,
      responseType: ResponseType.Json,
      headerProps
    })
  }

  patch<T>(url: string, body: object, headerProps?: HeaderProps) {
    return this.request<T>({
      path: url,
      body,
      method: HttpMethod.PATCH,
      requestType: RequestType.Basic,
      responseType: ResponseType.Json,
      headerProps
    })
  }

  put<T>(url: string, body: object, headerProps?: HeaderProps) {
    return this.request<T>({
      path: url,
      body,
      method: HttpMethod.PUT,
      requestType: RequestType.Basic,
      responseType: ResponseType.Json,
      headerProps
    })
  }

  delete<T>(url: string, body?: object, headerProps?: HeaderProps) {
    return this.request<T>({
      path: url,
      body: body || {},
      method: HttpMethod.DELETE,
      requestType: RequestType.Basic,
      responseType: ResponseType.Json,
      headerProps
    })
  }

  get<T>(url: string, query?: object, headerProps?: HeaderProps) {
    return this.request<T>({
      path: url,
      body: {},
      method: HttpMethod.GET,
      requestType: RequestType.Basic,
      responseType: ResponseType.Json,
      query,
      headerProps
    })
  }

  postFile<T>(url: string, body: { file: File; [index: string]: string | Blob }, headerProps?: HeaderProps) {
    const formData = new FormData()
    Object.keys(body).forEach(key => {
      formData.append(key, body[key])
    })

    return this.request<T>({
      path: url,
      body: formData,
      method: HttpMethod.POST,
      requestType: RequestType.Multipart,
      responseType: ResponseType.Json,
      headerProps
    })
  }

  private getUrl(path: string, query?: object) {
    return query ? `${baseUrl}/${path}/?${this.prepareQueryString(query)}` : `${baseUrl}/${path}/`
  }

  private prepareQueryString(query: object) {
    return queryString.stringify(query, { arrayFormat: 'comma', skipNull: true, skipEmptyString: true })
  }

  private request<T>({ path, body, method, requestType, responseType, query, headerProps }: RequestOptions) {
    const controller = new AbortController()

    return {
      req: fetch(
        this.getUrl(path, query),
        this.getRequestOptions(body, method, requestType, controller.signal, headerProps)
      )
        .then(async res => {
          if (res.status === 401) {
            const refreshTokenSuccess = !!(await this.refreshAccessToken())
            if (refreshTokenSuccess) {
              return fetch(
                this.getUrl(path, query),
                this.getRequestOptions(body, method, requestType, controller.signal, headerProps)
              )
            }
          }

          return res
        })
        .then(async res => {
          // todo: refactor to not request any data after session end. we will have to exclude login and logout from using this.request
          // better solution would be to end session on BE
          if (!!accessToken && getSessionTimeLeft().seconds < 0) {
            this.signOut({
              onSuccess: () => navigate(routes.sessionTimeout(getLastAccountType())),
              onRequestError: () => null
            })
          }
          setLastRequestTime()

          let resBody
          let responseTypeOverride = responseType

          try {
            resBody = null

            if (responseType === ResponseType.Json) {
              resBody = await res.json()
            } else if (responseType === ResponseType.Blob) {
              resBody = await res.blob()
              // error codes are sent as json so we need a fallback
              if (resBody.type === 'application/json') {
                resBody = JSON.parse(await resBody.text())
                responseTypeOverride = ResponseType.Json
              }
            }
          } catch (e) {
            resBody = null
          }

          if (res.ok) return { error: null as RequestError, body: resBody as T, status: res.status }
          return {
            error: new RequestError(
              res.status,
              responseTypeOverride === ResponseType.Json ? resBody?.internal_code : undefined,
              resBody?.data
            ),
            body: resBody?.data as T,
            status: res.status
          }
        })
        .catch(e => {
          if (e instanceof DOMException && e.name === 'AbortError') {
            throw e
          }

          return { error: e as RequestError, body: null as T, status: null as number }
        }),
      cancel: () => controller.abort()
    }
  }

  private getRequestOptions = (
    bodyData: object | FormData,
    method: HttpMethod,
    requestType: RequestType,
    signal?: AbortSignal,
    headerProps?: HeaderProps
  ) => {
    const body =
      method !== HttpMethod.GET
        ? requestType === RequestType.Multipart
          ? (bodyData as FormData)
          : JSON.stringify(bodyData)
        : undefined

    const headers: { [index: string]: string } = {
      'Study-Uuid': headerProps?.studyId || '',
      'Qr-Code': headerProps?.qrCode || '',
      Language: headerProps?.language || '',
      Authorization: accessToken ? `Bearer ${accessToken}` : ''
    }
    if (requestType === RequestType.Basic) {
      headers['Content-Type'] = 'application/json'
    }

    return {
      method,
      mode: 'cors' as RequestMode,
      headers,
      body,
      credentials: 'include' as RequestCredentials,
      signal
    }
  }
}

export const fetchApi = new Fetch()

export const FetchApiContext = React.createContext(fetchApi)
