/* eslint-disable camelcase */
import dayjs, { Dayjs } from 'dayjs'
import 'dayjs/locale/de.js'
import 'dayjs/locale/es.js'
import 'dayjs/locale/fr.js'
import 'dayjs/locale/it.js'
import 'dayjs/locale/ja.js'
import 'dayjs/locale/pl.js'
import 'dayjs/locale/pt.js'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import deCountries from 'world_countries_lists/data/countries/de/world.json'
import enCountries from 'world_countries_lists/data/countries/en/world.json'
import esCountries from 'world_countries_lists/data/countries/es/world.json'
import frCountries from 'world_countries_lists/data/countries/fr/world.json'
import itCountries from 'world_countries_lists/data/countries/it/world.json'
import jaCountries from 'world_countries_lists/data/countries/ja/world.json'
import plCountries from 'world_countries_lists/data/countries/pl/world.json'
import ptCountries from 'world_countries_lists/data/countries/pt/world.json'
import zhCountries from 'world_countries_lists/data/countries/zh/world.json'

import { createErrorsHandlers, localeFromPath, prepareSorter } from '../../utils'
import { BackendError } from '../RequestError'
import { SorterOrder } from '../SorterOrder'
import { AccountType, accountUrlPrefix } from '../auth'
import { fetchApi } from '../fetchApi'
import { CenterData } from './centers'
import { RoleName } from './roles'

dayjs.extend(advancedFormat)

interface RemoteTenantData {
  min_password_length: number
}

export interface TenantData {
  minPasswordLength: number
}

const parseRemoteTenantData = (remoteTenantData: RemoteTenantData) => ({
  minPasswordLength: remoteTenantData.min_password_length
})

interface FetchTenantDataResponseHandlers {
  onSuccess?: (tenantData: TenantData) => void
  onRequestError?: (code: number) => void
}

export const fetchTenantData = (responseHandlers?: FetchTenantDataResponseHandlers) => {
  const { req, cancel } = fetchApi.get<RemoteTenantData>('users/tenant-info')

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchTenantDataResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteTenantData(body))
    }
  })

  return cancel
}

export enum AvailableLocales {
  En = 'en',
  Fr = 'fr',
  It = 'it',
  Pl = 'pl',
  Pt = 'pt',
  De = 'de',
  Es = 'es',
  Ja = 'ja',
  Zh = 'zh'
}

const parseRemoteUserLanguage = (language: string) => {
  switch (language?.toUpperCase()) {
    case 'FR':
      return AvailableLocales.Fr
    case 'PL':
      return AvailableLocales.Pl
    case 'PT':
      return AvailableLocales.Pt
    case 'IT':
      return AvailableLocales.It
    case 'DE':
      return AvailableLocales.De
    case 'ES':
      return AvailableLocales.Es
    case 'JA':
      return AvailableLocales.Ja
    case 'ZH':
      return AvailableLocales.Zh
    default:
      return AvailableLocales.En
  }
}

export const localizedCountries = (locale: AvailableLocales) => {
  switch (locale) {
    case AvailableLocales.Fr:
      return frCountries
    case AvailableLocales.Pl:
      return plCountries
    case AvailableLocales.Pt:
      return ptCountries
    case AvailableLocales.It:
      return itCountries
    case AvailableLocales.De:
      return deCountries
    case AvailableLocales.Es:
      return esCountries
    case AvailableLocales.Ja:
      return jaCountries
    case AvailableLocales.Zh:
      return zhCountries
    default:
      return enCountries
  }
}

export enum LoginType {
  Password = 'PASSWORD',
  Saml = 'SAML'
}

interface RemoteCurrentUserData {
  id: number
  subject_uuid?: string
  name: string
  email?: string
  role: string
  language: string
  login_type: LoginType
  config: UserConfig
  data_analysis_enabled: boolean
  econsent_enabled: boolean
  econsult_enabled: boolean
  epro_enabled: boolean
  subject_repository_enabled: boolean
  calendar_enabled: boolean
  payments_enabled: boolean
  api_enabled: boolean
  recruitment_enabled: boolean
  side_by_side_enabled: boolean
  customisation_enabled: boolean
  center_ids?: number[]
  timezone?: string
  next_release: {
    next_release_active: boolean
    next_release_version: string
    next_release_notes_url: string
    next_release_date: string
  }
}

export interface NextRelease {
  active: boolean
  code: string
  notesUrl: string
  date: string
  time: string
  timezone: string
}

export interface CurrentUserData {
  id: string
  subjectId?: string
  name: string
  email?: string
  roleId: string
  language: AvailableLocales
  loginType: LoginType
  config: UserConfig
  isDataAnalysisEnabled: boolean
  isEconsentEnabled: boolean
  isEconsultEnabled: boolean
  isEproEnabled: boolean
  isSubjectRepositoryEnabled: boolean
  isRecruitmentEnabled: boolean
  isCalendarEnabled: boolean
  isPaymentsEnabled: boolean
  isApiEnabled: boolean
  isSideBySideEnabled: boolean
  nextRelease: NextRelease
  centerIds?: string[]
  timezone?: string
}

export const AutomaticTimezone = 'AUTOMATIC'

const parseRemoteCurrentUserData = (remoteUserData: RemoteCurrentUserData): CurrentUserData => {
  const nextRelease = remoteUserData.next_release
  const utcDateTime = nextRelease && dayjs.utc(nextRelease.next_release_date)
  const localDateTime = nextRelease && dayjs(utcDateTime).local()
  const timeZone = dayjs.tz.guess()
  return {
    id: String(remoteUserData.id),
    subjectId: remoteUserData.subject_uuid,
    name: remoteUserData.name,
    email: remoteUserData.email,
    roleId: remoteUserData.role,
    language: parseRemoteUserLanguage(remoteUserData.language),
    loginType: remoteUserData.login_type || LoginType.Password,
    config: remoteUserData.config,
    isDataAnalysisEnabled: remoteUserData.data_analysis_enabled,
    isEconsentEnabled: remoteUserData.econsent_enabled,
    isEconsultEnabled: remoteUserData.econsult_enabled,
    isEproEnabled: remoteUserData.epro_enabled,
    isSubjectRepositoryEnabled: remoteUserData.subject_repository_enabled,
    isApiEnabled: remoteUserData.api_enabled,
    isRecruitmentEnabled: remoteUserData.recruitment_enabled,
    isCalendarEnabled: remoteUserData.calendar_enabled,
    centerIds: remoteUserData.center_ids?.map(String) || [],
    isPaymentsEnabled: remoteUserData.payments_enabled,
    isSideBySideEnabled: remoteUserData.side_by_side_enabled,
    timezone: remoteUserData.timezone,
    nextRelease: {
      active: nextRelease?.next_release_active,
      code: nextRelease?.next_release_version,
      notesUrl: nextRelease?.next_release_notes_url,
      date: localDateTime?.format('YYYY-MM-DD'),
      time: localDateTime?.format('HH:mm'),
      timezone: timeZone
    }
  }
}

interface FetchCurrentUserDataResponseHandlers {
  onSuccess?: (userData: CurrentUserData) => void
  onRequestError?: (code: number) => void
}

export const fetchCurrentUserData = (
  { accountType }: { accountType: AccountType },
  responseHandlers?: FetchCurrentUserDataResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<RemoteCurrentUserData>(`${accountUrlPrefix(accountType)}/me`)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchCurrentUserDataResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteCurrentUserData(body))
    }
  })

  return cancel
}

export enum UserConfigKey {
  InclusionsTab = 'inclusion_tab',
  EproTab = 'epro_tab',
  DefaultOptionArrangement = 'default_option_arrangement',
  MenuMinimized = 'menu_minimized',
  DefaultInternationalPhoneNumber = 'default_international_phone_number',
  RMView = 'rm_view',
  LastVisits = 'last_visits',
  ClosedReleaseNotes = 'closedReleaseNotes',
  ViewOptionsKey = 'view_queries',
  CalendarStartsOnSunday = 'calendar_starts_on_sunday',
  CalendarShowWeekends = 'calendar_show_weekends',
  InclusionListCollapsed = 'inclusion_list_collapsed',
  InclusionChartCollapsed = 'inclusion_chart_collapsed',
  CalendarShowEarlyHours = 'calendar_show_early_hours',
  CalendarCenters = 'calendar_centers',
  RecentColors = 'recent_colors',
  LastAppointmentCenter = 'last_appointment_center'
}

export type ViewOptionsSorterValue<T> = {
  field: T
  order: SorterOrder
}

export type ViewOptionsValue = {
  search?: string
  sorter?: ViewOptionsSorterValue<string | number | symbol>
  filters?: Record<string, string[]>
  viewFilters?: Record<string, string[]>
}

export type UserConfigValue =
  | string
  | number
  | boolean
  | Record<string, string | number | boolean>
  | Record<string, ViewOptionsValue>
  | string[]

export type UserConfig = Record<UserConfigKey, UserConfigValue>

export const updateUserConfig = (config: UserConfig) => {
  fetchApi.patch('users/me/config', { config })
}

export interface RemoteUserData {
  id: number
  user_name: string
  full_name: string
  user?: number
  email: string
  company_name: string
  role_name: string
  colour: string
  type?: string
  pending: boolean
  is_locked: boolean
  is_sso: boolean
  last_login: string
  center_ids?: number[]
  has_access_to_all_studies_and_centers?: boolean
}

export enum UserType {
  User = 'user',
  Invitation = 'invitation'
}

export interface UserData {
  id: string
  globalUserId?: number
  name: string
  company: string
  email: string
  role: {
    name: RoleName
    color: string
  }
  pending: boolean
  type?: UserType
  locked: boolean
  sso: boolean
  lastLogin: Dayjs
  centers?: Partial<CenterData>[]
  hasAccessToAll?: boolean
}

export interface UsersSorter {
  field: keyof UserData
  order: SorterOrder
}

export const usersSorterFields = {
  name: ['user_name'],
  company: ['company_name'],
  role: ['role__name'],
  lastLogin: ['last_login']
}

const parseRemoteUserType = (type: string) => {
  switch (type) {
    case 'USER':
      return UserType.User
    case 'INVITATION':
      return UserType.Invitation
    default:
      return null
  }
}

export const parseRemoteUser = (user: RemoteUserData) => {
  return {
    id: String(user.id),
    name: user.user_name || user.full_name,
    company: user.company_name,
    email: user.email,
    globalUserId: user.user,
    role: {
      name: user.role_name,
      color: user.colour
    },
    pending: user.pending,
    type: user.type ? parseRemoteUserType(user.type) : null,
    locked: user.is_locked,
    sso: user.is_sso,
    lastLogin: user.last_login && dayjs(user.last_login).locale(localeFromPath()),
    centers: user.center_ids?.map(id => ({ id: String(id) })),
    hasAccessToAll: user.has_access_to_all_studies_and_centers
  }
}

interface FetchUsersOptions {
  options?: {
    limit?: number
    offset?: number
    sorter?: UsersSorter
    search?: string
    filters?: Record<string, string[]>
  }
}

interface FetchUsersResponseHandlers {
  onSuccess?: ({ users, allUsersCount }: { users: UserData[]; allUsersCount: number }) => void
  onRequestError?: (code: number) => void
}

interface FetchUsersResponse {
  count: number
  results: RemoteUserData[]
}

export const fetchUsers = ({ options }: FetchUsersOptions, responseHandlers?: FetchUsersResponseHandlers) => {
  const sorter = prepareSorter<typeof usersSorterFields, UsersSorter>(usersSorterFields, options.sorter, 'name')
  const query = {
    limit: options.limit,
    offset: options.offset,
    ordering: sorter,
    search: options.search,
    role: options.filters?.role
  }

  const { req, cancel } = fetchApi.get<FetchUsersResponse>('users', query)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchUsersResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess({ users: body.results.map(parseRemoteUser), allUsersCount: body.count })
    }
  })

  return cancel
}

interface InviteUserOptions {
  roleId: string
  email: string
  centerIds: string[]
}

const inviteUser = async ({ roleId, email, centerIds }: InviteUserOptions) => {
  const { req } = fetchApi.post('invitations/users/invite', {
    role_id: roleId,
    email: email.toLowerCase(),
    centers: centerIds
  })

  return req
}

interface InviteUsersOptions {
  roleId: string
  emails: string[]
  centerIds?: string[]
}

interface InviteUsersResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
  onEmailTaken?: (takenEmails: string[]) => void
  onError?: () => void
}

export const inviteUsers = (
  { roleId, emails, centerIds }: InviteUsersOptions,
  responseHandlers?: InviteUsersResponseHandlers
) => {
  if (!emails.length) return
  const requests = emails.map(email => inviteUser({ roleId, email, centerIds }))
  Promise.all(requests).then(results => {
    const takenEmails: string[] = []
    results.forEach(({ error, status }, index) => {
      if (error) {
        if (error.code === BackendError.USER_INVITATION_ALREADY_EXISTS) {
          takenEmails.push(emails[index])
        } else {
          createErrorsHandlers<Omit<InviteUsersResponseHandlers, 'onEmailTaken'>>({}, error, responseHandlers, status)
        }
      }
    })
    if (takenEmails.length && responseHandlers?.onEmailTaken) {
      responseHandlers.onEmailTaken(takenEmails)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })
}

interface EditUserOptions {
  userId: string
  centerIds?: string[]
  roleId: string
}

interface EditUserResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
  onLastAdmin?: () => void
  onError?: () => void
}

export const editUser = (
  { userId, roleId, centerIds }: EditUserOptions,
  responseHandlers?: EditUserResponseHandlers
) => {
  const { req, cancel } = fetchApi.patch(`users/${userId}`, { role_id: roleId, centers: centerIds })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<EditUserResponseHandlers>(
        {
          [BackendError.USER_LAST_ADMIN_CANNOT_CHANGE_ROLE]: 'onLastAdmin'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface DeleteUserOptions {
  userId: string
  userType: UserType
}

interface DeleteUserResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
  onLastAdmin?: () => void
  onError?: () => void
}

export const deleteUser = ({ userId, userType }: DeleteUserOptions, responseHandlers?: DeleteUserResponseHandlers) => {
  const { req, cancel } = fetchApi.delete(
    userType === UserType.User ? `users/${userId}` : `invitations/users/${userId}`
  )

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<DeleteUserResponseHandlers>(
        {
          [BackendError.USER_LAST_ADMIN_CANNOT_DELETE]: 'onLastAdmin'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface UnlockUserOptions {
  email: string
}

interface UnlockUserResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const unlockUser = ({ email }: UnlockUserOptions, responseHandlers?: UnlockUserResponseHandlers) => {
  const { req, cancel } = fetchApi.post('users/unlock', { email: email.toLowerCase() })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<UnlockUserResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface LockUserOptions {
  email: string
}

interface LockUserResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const lockUser = ({ email }: LockUserOptions, responseHandlers?: LockUserResponseHandlers) => {
  const { req, cancel } = fetchApi.post('users/lock', { email: email.toLowerCase() })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<LockUserResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface RemotePersonalDetails {
  first_name: string
  last_name: string
  company: string
  email: string
  language: string
  timezone: string
}

const parseRemotePersonalDetails = (remotePersonalDetails: RemotePersonalDetails) => ({
  firstName: remotePersonalDetails.first_name,
  lastName: remotePersonalDetails.last_name,
  company: remotePersonalDetails.company,
  email: remotePersonalDetails.email,
  language: parseRemoteUserLanguage(remotePersonalDetails.language),
  timezone: remotePersonalDetails.timezone
})

export interface PersonalDetails {
  firstName: string
  lastName: string
  company: string
  email: string
  language: AvailableLocales
  timezone: string
}

interface FetchPersonalDetailsResponseHandlers {
  onSuccess?: (personalDetails: PersonalDetails) => void
  onRequestError?: (code: number) => void
}

export const fetchPersonalDetails = (responseHandlers?: FetchPersonalDetailsResponseHandlers) => {
  const { req, cancel } = fetchApi.get<RemotePersonalDetails>('users/me/details')

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchPersonalDetailsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemotePersonalDetails(body))
    }
  })

  return cancel
}

interface UpdatePersonalDetailsResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const updatePersonalDetails = (
  personalDetails: PersonalDetails,
  responseHandlers?: UpdatePersonalDetailsResponseHandlers
) => {
  const { req, cancel } = fetchApi.patch('users/me/details', {
    first_name: personalDetails.firstName,
    last_name: personalDetails.lastName,
    company: personalDetails.company,
    language: personalDetails.language.toUpperCase(),
    timezone: personalDetails.timezone
  })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<UpdatePersonalDetailsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

export enum RegistrationStatus {
  Registered = 'REGISTERED',
  Unregistered = 'UNREGISTERED'
}

export interface LoginRemoteOption {
  type: LoginType
  name: string
  display_name: string
  logo: string
  endpoint: string
  sign_ecrf_endpoint: string
  sign_econsent_endpoint: string
  exclude_endpoint: string
  import_econsent_endpoint: string
}

export interface LoginOption {
  type: LoginType
  name: string
  displayName: string
  logo: string
  loginEndpoint: string
  signEcrfEndpoint: string
  signEconsentEndpoint: string
  excludeEndpoint: string
  importEconsentEndpoint: string
}

const parseLoginRemoteOption = (remoteLoginOption: LoginRemoteOption): LoginOption => ({
  type: remoteLoginOption.type,
  name: remoteLoginOption.name,
  displayName: remoteLoginOption.display_name,
  logo: remoteLoginOption.logo,
  loginEndpoint: remoteLoginOption.endpoint,
  signEcrfEndpoint: remoteLoginOption.sign_ecrf_endpoint,
  signEconsentEndpoint: remoteLoginOption.sign_econsent_endpoint,
  excludeEndpoint: remoteLoginOption.exclude_endpoint,
  importEconsentEndpoint: remoteLoginOption.import_econsent_endpoint
})

export interface AppVersion {
  code: string
  date: string
}

interface RemoteGeneralInfo {
  auth_options: LoginRemoteOption[]
  code_version: string
  release_date: string
}

const parseAppVersion = (remoteGeneralInfo: RemoteGeneralInfo) => ({
  code: remoteGeneralInfo.code_version,
  date: remoteGeneralInfo.release_date
})

interface FetchGeneralInfoResponseHandlers {
  onSuccess?: (loginOptions: LoginOption[], appVersion?: AppVersion) => void
  onRequestError?: (code: number) => void
}

export const fetchGeneralInfo = (responseHandlers?: FetchGeneralInfoResponseHandlers) => {
  const { req, cancel } = fetchApi.get<RemoteGeneralInfo>('users/general_info')

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchGeneralInfoResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.auth_options.map(parseLoginRemoteOption), parseAppVersion(body))
    }
  })

  return cancel
}
