/* eslint-disable camelcase */
import { createErrorsHandlers } from '../../utils'
import { Feature } from '../Feature'
import { BackendError } from '../RequestError'
import { EconsentStatus } from '../econsent'
import { fetchApi } from '../fetchApi'
import { CandidateStatus } from '../recruitment'
import { RecordStatus } from '../sectionAdvancement'
import {
  RemoteSubjectInRepository,
  SubjectInRepository,
  SubjectInRepositoryOptions,
  parseRemoteSubject
} from '../subjectRepository'
import { phoneNumberToString } from '../subjects'

interface RemoteSubjectDashboardRecord {
  study_name: string
  form_name: string
  type: Feature
  is_finished: boolean
  is_archived: boolean
  link: string
  token: string
  status: RecordStatus | EconsentStatus | CandidateStatus
  start_date: string
  answered_questions_count: number
  total_questions_count: number
  bank_details_validation: boolean
}

export interface SubjectDashboardRecord {
  studyName: string
  formName: string
  type: Feature
  isFinished: boolean
  isArchived: boolean
  link: string
  token: string
  status: RecordStatus | EconsentStatus | CandidateStatus
  date: Date
  answeredQuestions: number
  totalQuestions: number
  bankDetailsValidation: boolean
}

const parseRemoteSubjectDashboardRecord = (remoteDashboardLink: RemoteSubjectDashboardRecord) => {
  return {
    studyName: remoteDashboardLink.study_name,
    formName: remoteDashboardLink.form_name,
    type: remoteDashboardLink.type,
    isFinished: remoteDashboardLink.is_finished,
    isArchived: remoteDashboardLink.is_archived,
    link: remoteDashboardLink.link,
    token: remoteDashboardLink.token,
    status: remoteDashboardLink.status,
    date: remoteDashboardLink.start_date ? new Date(remoteDashboardLink.start_date) : undefined,
    answeredQuestions: remoteDashboardLink.answered_questions_count,
    totalQuestions: remoteDashboardLink.total_questions_count,
    bankDetailsValidation: remoteDashboardLink.bank_details_validation
  }
}

interface FetchSubjectDashboardResponseHandlers {
  onSuccess?: (links: SubjectDashboardRecord[]) => void
  onRequestError?: (code: number) => void
}

export const fetchSubjectDashboard = (responseHandlers?: FetchSubjectDashboardResponseHandlers) => {
  const { req, cancel } = fetchApi.get<RemoteSubjectDashboardRecord[]>('subject_accounts/dashboard')

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchSubjectDashboardResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.map(parseRemoteSubjectDashboardRecord))
    }
  })

  return cancel
}

interface FetchSubjectDetailsResponseHandlers {
  onSuccess?: (subject: SubjectInRepository) => void
  onRequestError?: (code: number) => void
}

export const fetchSubjectDetails = (responseHandlers?: FetchSubjectDetailsResponseHandlers) => {
  const { req, cancel } = fetchApi.get<RemoteSubjectInRepository>(`subject_accounts/me`)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchSubjectDetailsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteSubject(body))
    }
  })

  return cancel
}

interface EditSubjectDetailsResponseHandlers {
  onSuccess?: (subject: SubjectInRepository) => void
  onRequestError?: (code: number) => void
  onError?: () => void
}

export const editSubjectDetails = (
  { firstName, lastName, email, internationalPhoneNumber, language }: SubjectInRepositoryOptions,
  responseHandlers?: EditSubjectDetailsResponseHandlers
) => {
  const query = {
    first_name: firstName || null,
    last_name: lastName || null,
    email: email || null,
    phone: phoneNumberToString(internationalPhoneNumber) || null,
    language: language || null
  }
  const { req, cancel } = fetchApi.patch<RemoteSubjectInRepository>(`subject_accounts/me`, query, {})

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<EditSubjectDetailsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteSubject(body))
    }
  })

  return cancel
}

export interface RemoteSubjectAccountDetails {
  country_code: string
  account_beneficiary: string
  account_number: string
  swift: string
}

export interface SubjectAccountDetails {
  countryCode: string
  accountBeneficiary: string
  accountNumber: string
  swift: string
}

interface FetchSubjectAccountDetailsResponseHandlers {
  onSuccess?: (accountDetails: SubjectAccountDetails) => void
  onRequestError?: (code: number) => void
}

export const fetchSubjectAccountDetails = (responseHandlers?: FetchSubjectAccountDetailsResponseHandlers) => {
  const { req, cancel } = fetchApi.get<RemoteSubjectAccountDetails>(`subject_accounts/me/bank_account`)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchSubjectAccountDetailsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteSubjectAccountDetails(body))
    }
  })

  return cancel
}

export const parseRemoteSubjectAccountDetails = (remoteSubjectAccountDetails: RemoteSubjectAccountDetails) => ({
  countryCode: remoteSubjectAccountDetails.country_code,
  accountBeneficiary: remoteSubjectAccountDetails.account_beneficiary,
  accountNumber: remoteSubjectAccountDetails.account_number,
  swift: remoteSubjectAccountDetails.swift
})

interface EditSubjectAccountDetailsResponseHandlers {
  onSuccess?: (subject: SubjectAccountDetails) => void
  onRequestError?: (code: number) => void
  onError?: () => void
}

export const editSubjectAccountDetails = (
  { countryCode, accountBeneficiary, accountNumber, swift }: SubjectAccountDetails,
  responseHandlers?: EditSubjectAccountDetailsResponseHandlers
) => {
  const query = {
    country_code: countryCode || null,
    account_beneficiary: accountBeneficiary || null,
    account_number: accountNumber || null,
    swift: swift || null
  }
  const { req, cancel } = fetchApi.patch<RemoteSubjectAccountDetails>(`subject_accounts/me/bank_account`, query, {})

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<EditSubjectAccountDetailsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteSubjectAccountDetails(body))
    }
  })

  return cancel
}

interface EditSubjectEmailResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
  onError?: () => void
  onEmailAlreadyUsed?: () => void
}

export interface EditSubjectEmailOptions {
  email: string
}

export const editSubjectEmail = (
  { email }: EditSubjectEmailOptions,
  responseHandlers?: EditSubjectEmailResponseHandlers
) => {
  const { req, cancel } = fetchApi.post(`subject_accounts/change_email`, { email }, {})

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<EditSubjectEmailResponseHandlers>(
        {
          [BackendError.SUBJECT_EMAIL_ALREADY_TAKEN]: 'onEmailAlreadyUsed',
          [BackendError.USER_NEW_EMAIL_SAME_AS_OLD]: 'onEmailAlreadyUsed'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

export const editSubjectEmailResend = (
  { email }: EditSubjectEmailOptions,
  responseHandlers?: EditSubjectEmailResponseHandlers
) => {
  const { req, cancel } = fetchApi.post('subject_accounts/change_email/resend', { email })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<EditSubjectEmailResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface VerifyEmailResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const verifyEmail = ({ token }: { token: string }, responseHandlers?: VerifyEmailResponseHandlers) => {
  const path = `subject_accounts/change_email/confirm`
  const { req, cancel } = fetchApi.post(path, { token })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<VerifyEmailResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface SendDeletionRequestResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
  onInvalidPassword?: () => void
  onError?: () => void
}

export const sendDeletionRequest = (
  { password }: { password: string },
  responseHandlers?: SendDeletionRequestResponseHandlers
) => {
  const { req, cancel } = fetchApi.post('subject_accounts/account_deletion_requests', { password })

  req.then(({ error }) => {
    if (error) {
      createErrorsHandlers<SendDeletionRequestResponseHandlers>(
        {
          [BackendError.USER_PASSWORD_WRONG]: 'onInvalidPassword'
        },
        error,
        responseHandlers
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface CancelDeletionRequestResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const cancelDeletionRequest = (responseHandlers?: CancelDeletionRequestResponseHandlers) => {
  const { req, cancel } = fetchApi.delete('subject_accounts/account_deletion_requests')

  req.then(({ error }) => {
    if (error) {
      createErrorsHandlers<CancelDeletionRequestResponseHandlers>({}, error, responseHandlers)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface RenewConsentsResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const renewConsents = (consents: Record<string, boolean>, responseHandlers?: RenewConsentsResponseHandlers) => {
  const { req, cancel } = fetchApi.post('subject_accounts/renew_consents', consents)

  req.then(({ error }) => {
    if (error) {
      createErrorsHandlers<CancelDeletionRequestResponseHandlers>({}, error, responseHandlers)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface CheckSubjectAccountResponseHandlers {
  onSuccess?: (exists: boolean) => void
  onNotFound?: () => void
  onRequestError?: (code: number) => void
}

interface CheckSubjectAccountResponse {
  exists: boolean
}

export const checkSubjectAccount = (
  { email }: { email: string },
  responseHandlers?: CheckSubjectAccountResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<CheckSubjectAccountResponse>('subject_accounts/auth/exists', { email })

  req.then(({ body, error, status }) => {
    if (error) {
      createErrorsHandlers<CheckSubjectAccountResponseHandlers>(
        {
          404: 'onNotFound'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.exists)
    }
  })

  return cancel
}
