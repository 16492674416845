/* eslint-disable camelcase */
import { createErrorsHandlers, prepareSorter } from '../../utils'
import { BackendError } from '../RequestError'
import { SorterOrder } from '../SorterOrder'
import { SelectionKeys } from '../exports'
import { fetchApi } from '../fetchApi'
import {
  RemoteSubjectInRepository,
  SimpleAnswer,
  SubjectInRepository,
  parseRemoteSimpleAnswer,
  parseRemoteSubject
} from '../subjectRepository'
import { InternationalPhoneNumber, parseRemotePhoneNumber } from '../subjects'
import { TableColumnVariableSource } from '../variables'

export enum RemoteCandidateStatus {
  New = 'NEW',
  FollowUpRequired = 'FOLLOW_UP_REQUIRED',
  Unqualified = 'UNQUALIFIED',
  QualifiedForScreening = 'QUALIFIED_FOR_SCREENING',
  Rejected = 'REJECTED',
  Enrolled = 'ENROLLED',
  Completed = 'COMPLETED'
}

export enum CandidateStatus {
  New = 'NEW',
  FollowUpRequired = 'FOLLOW_UP_REQUIRED',
  Disqualified = 'DISQUALIFIED',
  Qualified = 'QUALIFIED',
  Excluded = 'EXCLUDED',
  Enrolled = 'ENROLLED',
  Completed = 'COMPLETED'
}

export const candidateStatusMapping = {
  [RemoteCandidateStatus.New]: CandidateStatus.New,
  [RemoteCandidateStatus.FollowUpRequired]: CandidateStatus.FollowUpRequired,
  [RemoteCandidateStatus.Unqualified]: CandidateStatus.Disqualified,
  [RemoteCandidateStatus.QualifiedForScreening]: CandidateStatus.Qualified,
  [RemoteCandidateStatus.Rejected]: CandidateStatus.Excluded,
  [RemoteCandidateStatus.Enrolled]: CandidateStatus.Enrolled,
  [RemoteCandidateStatus.Completed]: CandidateStatus.Completed
}

export const candidateStatusForSaveMapping = {
  [CandidateStatus.New]: RemoteCandidateStatus.New,
  [CandidateStatus.FollowUpRequired]: RemoteCandidateStatus.FollowUpRequired,
  [CandidateStatus.Disqualified]: RemoteCandidateStatus.Unqualified,
  [CandidateStatus.Qualified]: RemoteCandidateStatus.QualifiedForScreening,
  [CandidateStatus.Excluded]: RemoteCandidateStatus.Rejected,
  [CandidateStatus.Enrolled]: RemoteCandidateStatus.Enrolled,
  [CandidateStatus.Completed]: RemoteCandidateStatus.Completed
}

export const clearRemoteCandidateStatus = <T>(status: T | RemoteCandidateStatus) => {
  if (Object.values(RemoteCandidateStatus).includes(status as RemoteCandidateStatus))
    return candidateStatusMapping[status as RemoteCandidateStatus]
  return status as T
}

export interface Candidate {
  id: string
  datacaptId: string
  firstName: string
  lastName: string
  email: string
  internationalPhoneNumber?: InternationalPhoneNumber
  appliedDate: Date
  progress: number
  status: CandidateStatus
  language: string
  centerId: string
  centerAbbreviation?: string
  note: string
  variableAnswers?: Record<TableColumnVariableSource, Record<string, SimpleAnswer>>
  photoThumbnail: string
}

export interface RemoteCandidate {
  id: number
  datacapt_id: string
  first_name: string
  last_name: string
  email: string
  phone: string
  language: string
  applied_date: string
  status: RemoteCandidateStatus
  progress: number
  center_id: number
  note: string
  photo_thumbnail: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  variable_answers?: Record<TableColumnVariableSource, Record<string, any>>
}

interface FetchCandidateResponse {
  count: number
  next: number
  results: RemoteCandidate[]
}

const parseRemoteCandidate: (candidate: RemoteCandidate) => Candidate = (candidate: RemoteCandidate) => ({
  id: String(candidate.id),
  datacaptId: candidate.datacapt_id,
  firstName: candidate.first_name,
  lastName: candidate.last_name,
  email: candidate.email,
  internationalPhoneNumber: parseRemotePhoneNumber(candidate.phone),
  language: candidate.language,
  appliedDate: candidate.applied_date ? new Date(candidate.applied_date) : undefined,
  progress: candidate.progress,
  status: candidateStatusMapping[candidate.status],
  centerId: String(candidate.center_id),
  note: candidate.note,
  photoThumbnail: candidate.photo_thumbnail,
  variableAnswers: Object.fromEntries(
    (Object.keys(candidate.variable_answers) as TableColumnVariableSource[]).map(source => {
      return [
        source,
        source
          ? {
              ...Object.entries(candidate.variable_answers[source]).reduce(
                (acc, [key, value]) => ({ ...acc, [key]: parseRemoteSimpleAnswer(value) }),
                {}
              )
            }
          : {}
      ]
    })
  ) as Record<TableColumnVariableSource, Record<string, SimpleAnswer>>
})

export interface CandidatesSorter {
  field: keyof Candidate
  order: SorterOrder
}

const sorterFields = {
  email: ['subject__first_name', 'subject__last_name', 'subject__email'],
  stage: ['status'],
  appliedDate: ['applied_date'],
  survey: ['progress'],
  centerAbbreviation: ['center__abbreviation']
}

interface FetchCandidatesOptions {
  studyId: string
  options?: {
    limit?: number
    offset?: number
    sorter?: CandidatesSorter
    search?: string
    filters?: {
      status: string[]
    }
  }
}

interface FetchCandidatesResponseHandlers {
  onSuccess?: ({ candidates, countAll }: { candidates: Candidate[]; countAll: number }) => void
  onRequestError?: (code: number) => void
}

export const fetchCandidates = (
  { studyId, options }: FetchCandidatesOptions,
  responseHandlers?: FetchCandidatesResponseHandlers
) => {
  const sorter = prepareSorter<typeof sorterFields, CandidatesSorter>(sorterFields, options.sorter)
  const query = {
    limit: options.limit,
    offset: options.offset,
    ordering: sorter,
    search: options.search,
    status: options.filters?.status?.map(s => candidateStatusForSaveMapping[s as CandidateStatus])
  }

  const { req, cancel } = fetchApi.get<FetchCandidateResponse>(`recruitment/studies/${studyId}/records/list`, query)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchCandidatesResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess({
        candidates: body.results.map(parseRemoteCandidate),
        countAll: body.count
      })
    }
  })

  return cancel
}

interface FetchAllSelectedCandidatesOptions {
  studyId: string
  options?: {
    search?: string
    status: string[]
  }
}
interface FetchAllSelectedCandidatesResponseHandlers {
  onSuccess?: (candidates: Candidate[]) => void
  onRequestError?: (code: number) => void
}

export const fetchAllSelectedCandidates = (
  { studyId, options }: FetchAllSelectedCandidatesOptions,
  responseHandlers?: FetchAllSelectedCandidatesResponseHandlers
) => {
  const query = {
    search: options.search,
    status: options.status
  }

  const { req, cancel } = fetchApi.get<RemoteCandidate[]>(`recruitment/studies/${studyId}/records/list/full`, query)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchAllSelectedCandidatesResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.map(parseRemoteCandidate))
    }
  })

  return cancel
}

interface FetchCandidateOptions {
  candidateId: string
  studyId: string
}

interface FetchCandidateResponseHandlers {
  onSuccess?: ({ candidate }: { candidate: Candidate }) => void
  onRequestError?: (code: number) => void
}

export const fetchCandidate = (
  { candidateId, studyId }: FetchCandidateOptions,
  responseHandlers?: FetchCandidateResponseHandlers
) => {
  // todo: add support to fetch without studyId
  const { req, cancel } = fetchApi.get<RemoteCandidate>(`recruitment/studies/${studyId}/records/${candidateId}`)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchCandidateResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess({
        candidate: parseRemoteCandidate(body)
      })
    }
  })

  return cancel
}

interface UpdateCandidateStatusOptions {
  candidateId: string
  status: CandidateStatus
  studyId: string
}

interface UpdateCandidateStatusResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const updateCandidateStatus = (
  { candidateId, status, studyId }: UpdateCandidateStatusOptions,
  responseHandlers: UpdateCandidateStatusResponseHandlers
) => {
  const { req, cancel } = fetchApi.patch(`recruitment/studies/${studyId}/records/${candidateId}`, {
    status: candidateStatusForSaveMapping[status]
  })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<UpdateCandidateStatusResponseHandlers>({}, error, responseHandlers, status)
    } else {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface UpdateCandidateNoteOptions {
  candidateId: string
  note: string
  studyId: string
}

interface UpdateCandidateNoteResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const updateCandidateNote = (
  { candidateId, note, studyId }: UpdateCandidateNoteOptions,
  responseHandlers: UpdateCandidateNoteResponseHandlers
) => {
  const { req, cancel } = fetchApi.patch(`recruitment/studies/${studyId}/records/${candidateId}`, { note })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<UpdateCandidateNoteResponseHandlers>({}, error, responseHandlers, status)
    } else {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

export interface InviteCandidatesOptions {
  emails: string[]
  subjectIds: string[]
  centerId?: string
  studyId: string
  isSendingInvitations: boolean
}

interface InviteCandidatesResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const inviteCandidates = (
  { emails, subjectIds, centerId, studyId, isSendingInvitations }: InviteCandidatesOptions,
  responseHandlers?: InviteCandidatesResponseHandlers
) => {
  const { req, cancel } = fetchApi.post(`invitations/recruitment/${studyId}/invite`, {
    emails,
    datacapt_ids: subjectIds,
    center: centerId,
    send_invitations: isSendingInvitations
  })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<InviteCandidatesResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface FetchSubjectsNotInRecruitmentOptions {
  studyId: string
  searchPhrase: string
}

interface FetchSubjectsNotInRecruitmentResponse {
  results: RemoteSubjectInRepository[]
}

interface FetchSubjectsNotInRecruitmentResponseHandlers {
  onSuccess?: (subjects: SubjectInRepository[]) => void
  onRequestError?: (code: number) => void
}

export const fetchSubjectsNotInRecruitment = (
  { studyId, searchPhrase: search }: FetchSubjectsNotInRecruitmentOptions,
  responseHandlers: FetchSubjectsNotInRecruitmentResponseHandlers
) => {
  const url = `recruitment/studies/${studyId}/invite`
  const { req, cancel } = fetchApi.get<FetchSubjectsNotInRecruitmentResponse>(url, { search })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchSubjectsNotInRecruitmentResponseHandlers>({}, error, responseHandlers, status)
    } else {
      responseHandlers.onSuccess(body.results?.map(subject => parseRemoteSubject(subject)))
    }
  })

  return cancel
}

interface BulkUpdateCandidatesStatusResponseHandlers {
  onSuccess?: () => void
  onWrongStatus?: () => void
  onNoCurrency?: () => void
  onRequestError?: (code: number) => void
}

interface BulkUpdateCandidatesStatusOptions {
  search: string
  records: SelectionKeys
  status: string[]
  studyId: string
  targetStatus: CandidateStatus
  paymentAmount: number
}

export const bulkUpdateCandidatesStatus = (
  { targetStatus, paymentAmount, search, status, records, studyId }: BulkUpdateCandidatesStatusOptions,
  responseHandlers?: BulkUpdateCandidatesStatusResponseHandlers
) => {
  const path = `recruitment/studies/${studyId}/records/status`
  const query = {
    search: search || undefined,
    records,
    status: status?.length ? status.join(',') : undefined,
    target_status: candidateStatusForSaveMapping[targetStatus],
    payment_amount: paymentAmount
  }
  const { req, cancel } = fetchApi.post(path, query)

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<BulkUpdateCandidatesStatusResponseHandlers>(
        {
          [BackendError.RECRUITMENT_RECORD_STATUS_ILLEGAL_TRANSITION]: 'onWrongStatus',
          [BackendError.PAYMENT_NO_CURRENCY]: 'onNoCurrency'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}
